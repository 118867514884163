.icon-bar {
  position: absolute;
  top: 30%;
  right: 0%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}


.summerSize{
  width: inherit;
  height: inherit;
}
